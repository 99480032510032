@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
}

.hero {
  background: url(./images/bg_illustration_3.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}

.slick-slide > div {
  margin: 0 10px;
}

.fun {
  color:#FFB801;
}

.banner {
  background: url(./images/demobanner.svg) center no-repeat;
  background-size: cover;
}

li {
display: flex;
}
/* .courseCard {
  &:hover {
    border: 1px solid black;

    .borderLine {
      border-bottom: 1px solid black;
    }

    .buttonContainer {
      border-top: 1px solid black;
    }
  }
} */

/* Add this CSS to your stylesheet */
.attention-grabbing-link {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(45deg, #ffba00, #ff4500);
  border-radius: 8px;
  overflow: hidden;
  padding: 8px 16px;
}

.attention-grabbing-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75%;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transform: skewX(-25deg);
  animation: shine 1s infinite;
}

@keyframes shine {
  0% {
    left: -75%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 150%;
  }
}



